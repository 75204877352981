import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"


const angajamentSLA = () => {
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="Angajament de calitate SLA"
        homePageText="Home"
        homePageUrl="/"
        activePageText="Angajament de calitate SLA"
      />
      <section className="privacy-policy-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="privacy-policy-content">
                <p>Acest document conţine Angajamentul de Calitate de tip SLA oferit de EXIM HOST SRL prin intermediul SiteBunker (<a href="www.SiteBunker.ro" aria-invalid="true">www.SiteBunker.ro</a>) sau alte site-uri deținute.</p>
                <p>Începand cu 1 martie 2021, Sitebunker.ro (EXIM HOST SRL) va garanta funcționalitatea serviciilor de hosting cu un uptime de 99.9% lunar. Uptime-ul este măsurat DOAR de către sistemele noastre de monitorizare, oferite de Uptime Doctor.<br />Angajamentul de calitate sau SLA (Service Level Agreement) se aplică pentru toate serviciile oferite de noi.</p>
                <p>Astfel, dacă serviciul dvs de shared hosting, VPS sau server dedicat este afectat de o avarie (downtime) sau o lucrare de mentenanță care nu a fost anunțată în prealabil cu minim 1 ora, vă vom oferi o compensare pentru nefuncționalitate astfel:</p>
                <p>• nefuncționalitate intre 0 și 59 de minute intr-o lună (aproximativ 0.1% din lună) – nu se oferă compensare</p>
                <p>• nefuncționalitate peste 59 de minute intr-o lună – pentru fiecare 1 oră de downtime vă oferim 1 zi de serviciu gratuit (vom prelungi scadența serviciului dvs contractat cu 1 zi)</p>
                <p>Pentru a beneficia de compensare, clientul trebuie să trimită o solicitare catre echipa Sitebunker.ro în maxim 14 zile de la incident, iar downtime-ul trebuie să fie confirmat de echipa noastră tehnica.</p>
                <p>Limita maximă pentru compensare este de 1 lună (30 de zile calendaristice) adaugată în mod gratuit serviciului afectat de downtime. Disponibilitatea serviciului (Uptime-ul) se masoară pentru o lună calendaristică, excluzand perioadele de mentenanță anunțate în prealabil.</p>
                <p>Pentru serviciile de VPS sau servere dedicate, disponibilitatea se masoară prin răspunsul serverului la interogarea de tip ping, excluzand situația în care protocolul ICMP este oprit în firewall-ul folosit de client.</p>
                <p>Compensațiile NU se oferă dacă orice eroare respectiv nefuncționare a serviciului este cauzată sau generată de:</p>
                <p>• defecțiuni hardware la nivel de server sau rețea<br />• probleme legate de DNS sau disfunctionalități la servere DNS care nu sunt controlate de EXIM HOST SRL<br />• funcționarea defectuoasă a serviciilor din cauze care nu se află sub controlul Sitebunker.ro sau care nu pot fi prevăzute de către Sitebunker<br />• întreruperea telecomunicațiilor (cauzată de factori externi), atacurile asupra rețelelor, congestionarea rețelei sau alte avarii, precum și secționarea accidentală a cel puțin unei fibre optice<br />• calamități naturale, cutremure, războaie<br />• indisponibilitatea sau funcționarea defectuoasă a unui website, server VPS sau server dedicat din motive ce țin de client (erori în website, malware, viruși, alte atacuri informatice asupra website-ului sau serverului clientului) sau a softurilor folosite de acesta.<br />• incendiu, viruși, hackeri, atacuri malware<br />• lucrări de mentenanță anunțate<br />• probleme de acces ale clientului cu FTP, POP3, SMTP, DNS, HTTP, HTTPS, IMAP, IP-uri blocate la nivel de firewall<br />• nefuncționarea corespunzatoare a unui software oferit dintr-o altă sursă (scripturi gratuite, software de comerț electronic sau procesatoare de plăți)<br />• atacuri DDoS, Flood, acțiuni ale autorităților, embargouri.</p>
                <p>EXIM HOST SRL oferă garanția menționată mai sus dar nu ne asumăm răspunderea pentru nici o daună, pierdere de profit, precum și ratarea unor oportunități sau orice alte pierderi suferite de client sau de alte terțe persoane, în mod direct sau indirect.</p>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
)
}
export default angajamentSLA
